<template>
  <div class="important-page-c display--flex fd--column">
    <!-- 搜索 -->
    <div class="search-form btn-2b5a95">
      <div
        class="l-w"
        :style="{
          height: $store.state.global.isSearchExtend_1
            ? ''
            : $store.state.global.searchFormHeight_1 + 10 + 'px',
        }"
      >
        <el-form
          label-position="right"
          :class="
            $store.state.global.isSearchExtend_1 ? '' : 'overflow--hidden'
          "
          :model="searchForm"
					@submit.native.prevent
        >
          <el-form-item label="姓名:" prop="username">
            <el-input 
							v-model.trim="searchForm.username" 
							@keyup.enter.native="seach"
							clearable />
          </el-form-item>
          <el-form-item label="性别要求" prop="sexval">
            <el-select 
							v-model="searchForm.sexval" 
							@keyup.enter.native="seach"
							@visible-change="visibleType"
							ref="selectref"
							clearable>
              <el-option label="男" value="男" />
              <el-option label="女" value="女" />
              <el-option label="不限" :value="null" />
            </el-select>
          </el-form-item>
          <el-form-item label="联系电话:" prop="contactphone">
            <el-input 
							v-model.trim="searchForm.contactphone" 
							@keyup.enter.native="seach"
							clearable />
          </el-form-item>
          <el-form-item label="职位:" prop="positionname">
            <el-input 
							v-model.trim="searchForm.positionname" 
							@keyup.enter.native="seach"
							clearable />
          </el-form-item>
          <el-form-item label="学历:" prop="eduval">
            <el-input 
							v-model.trim="searchForm.eduval" 
							@keyup.enter.native="seach"
							clearable />
          </el-form-item>
          <el-form-item label="毕业学校:" prop="graduateschool">
            <el-input 
							v-model.trim="searchForm.graduateschool" 
							@keyup.enter.native="seach"
							clearable />
          </el-form-item>
          <el-form-item label="专业:" prop="major">
            <el-input 
							v-model.trim="searchForm.major" 
							@keyup.enter.native="seach"
							clearable />
          </el-form-item>
          <el-form-item label="教学点:" prop="schoolorgname">
            <el-input 
							v-model.trim="searchForm.schoolorgname" 
							@keyup.enter.native="seach"
							clearable />
          </el-form-item>
        </el-form>
      </div>
      <div class="r-w">
        <el-button
          type="primary"
          icon="el-icon-search"
          @click="seach"
          :loading="!$store.state.global.isEndRequest"
          class="btn-2b5a95"
          >搜索</el-button
        >
				<el-button type="primary" @click="resetQuery">重置</el-button>
        <el-button
          type="primary"
          :icon="
            $store.state.global.isSearchExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          v-if="$store.state.global.searchExtend_1"
          @click="onExtendClick('isSearchExtend_1')"
          >{{ $store.state.global.searchExtendText_1 }}</el-button
        >
      </div>
    </div>

    <!-- 按钮操作 -->
    <div
      class="Btn-w eaf4ff-btn"
      v-if="tableRes.buttonlist && tableRes.buttonlist.length"
      :style="{
        height: $store.state.global.isBtnExtend_1
          ? ''
          : $store.state.global.BtnHeight_1 + 10 + 'px',
      }"
    >
      <div
        class="btn-l"
        v-if="tableRes.buttonlist"
        :class="$store.state.global.isBtnExtend_1 ? '' : 'overflow--hidden'"
      >
        <el-button
          v-for="(item, index) in tableRes.buttonlist"
          :key="index"
          type="primary"
          @click="onClickBtn(item.buttonname)"
          >{{ item.name }}
        </el-button>
      </div>
      <div class="Btn-extend">
        <el-button
          type="primary"
          v-if="$store.state.global.btnExtend_1"
          class="btn-2b5a95"
          :icon="
            $store.state.global.isBtnExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          @click="onExtendClick('isBtnExtend_1')"
          >{{ $store.state.global.BtnExtendText_1 }}</el-button
        >
      </div>
    </div>

    <!-- 表格 -->
    <el-table
      :data="tableRes.list"
      stripe
      border
      :header-cell-style="{ background: '#044d8c', color: '#fff'}"
      ref="multipleTable"
      style="width: 100%"
      :cell-style="{ padding: 0 }"
      @selection-change="
        (val, multipleSelection) =>
          handleSelectionChange(val, 'multipleSelection')
      "
    >
      <el-table-column type="selection" width="55" />
      <el-table-column label="姓名" prop="username" align="center">
        <template slot-scope="scope">
          <el-button @click="btnShowDetail(scope.row)" type="text">{{
            scope.row.username
          }}</el-button>
        </template>
      </el-table-column>

      <el-table-column label="性别" prop="sexval" align="center" />
      <el-table-column label="联系电话" prop="contactphone" align="center" />
      <el-table-column label="职位" prop="positionname" align="center" />
      <el-table-column label="学历" prop="eduval" align="center" />
      <el-table-column label="毕业学校" prop="graduateschool" align="center" />
      <el-table-column label="专业" prop="major" align="center" />
      <el-table-column label="教学点" prop="schoolorgname" align="center" />
      <el-table-column
        label="操作"
        prop="schoolorgname"
        align="center"
        width="350"
      >
        <template slot-scope="scope">
          <el-button type="text" @click="btnJianli(scope.row)"
            >下载简历</el-button
          >
          <el-button type="text" @click="btnInterview(scope.row)"
            >预约面试</el-button
          >
          <el-button
            type="text"
            @click="
              (url, modal, params, row, prop, page) =>
                onSelectData(
                  _userinterview_list,
                  'showUserinterview',
                  { personid: scope.row.id },
                  scope.row,
                  'userinterview_select',
                  'userinterview_page'
                )
            "
            >预约信息</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页 -->
    <el-pagination
      background
      @current-change="handleCurrentChange"
      :current-page="pageinfo.pageindex"
      :page-size="pageinfo.pagesize"
      :page-sizes="[10, 20, 30, 40, 50]"
      layout="total, prev, pager, next, jumper"
      :total="tableRes.totalcount"
    ></el-pagination>

    <!-- 查看详情 -->
    <el-dialog
      class="btn-2b5a95"
      :width="$store.state.global.isFullscreen ? 'width: 100%' : 'width: 80%'"
      :fullscreen="$store.state.global.isFullscreen"
      :visible.sync="showDetailModal"
      v-if="showDetailModal"
			:close-on-click-modal='false'
      append-to-body
      @closed="$store.state.global.isFullscreen = false"
    >
      <span slot="title" class="el-dialog__title">
        <span>查看详情</span>
        <span
          :class="
            $store.state.global.isFullscreen
              ? 'el-icon-copy-document'
              : 'el-icon-full-screen'
          "
          @click="isExpand($store.state.global.isFullscreen)"
        ></span>
      </span>
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        label-width="30%"
        class="demo-dynamic form-item-w-50"
        status-icon
        :rules="rules"
      >
        <el-form-item label="流水号:">
					{{dynamicValidateForm.id}}
        </el-form-item>
        <el-form-item label="分公司:">
					{{dynamicValidateForm.companyorgname}}
        </el-form-item>
        <el-form-item label="教学点:">
          {{ dynamicValidateForm.schoolorgname }}
        </el-form-item>
        <el-form-item label="部门:">
          {{ dynamicValidateForm.departorgname }}
        </el-form-item>
        <el-form-item label="填表人:">
          {{ dynamicValidateForm.applyusername }}
        </el-form-item>
        <el-form-item label="年龄:">
          {{ dynamicValidateForm.age }}
        </el-form-item>
        <el-form-item label="学历:">
          {{ dynamicValidateForm.eduval }}
        </el-form-item>
        <el-form-item label="性别要求:">
          {{ dynamicValidateForm.sexrequire }}
        </el-form-item>
        <el-form-item label="希望到职时间:">
          {{ dynamicValidateForm.wantedtime | formatDate("YYYY-MM-DD") }}
        </el-form-item>
        <el-form-item label="申请时间:">
          {{ dynamicValidateForm.applytime | formatDate("YYYY-MM-DD") }}
        </el-form-item>
        <el-form-item label="编制人数:">
          {{ dynamicValidateForm.allcount }}
        </el-form-item>
        <el-form-item label="经历:">
          {{ dynamicValidateForm.experience }}
        </el-form-item>
        <el-form-item label="当前人数:">
          {{ dynamicValidateForm.currentcount }}
        </el-form-item>
        <el-form-item label="具备技能:">
          {{ dynamicValidateForm.skill }}
        </el-form-item>
        <el-form-item label="拟招人数:">
          {{ dynamicValidateForm.applycount }}
        </el-form-item>
        <el-form-item label="增补岗位:">
          {{ dynamicValidateForm.positionname }}
        </el-form-item>
        <el-form-item label="申请增补理由:">
          {{ dynamicValidateForm.applyreason }}
        </el-form-item>
      </el-form>
    </el-dialog>

    <!--添加，修改-->
    <el-dialog
      class="btn-2b5a95"
			v-dialogDrag
      width="60%"
      :visible.sync="showAddModal"
      v-if="showAddModal"
			:close-on-click-modal='false'
      append-to-body
			:title="this.dialogTitle"
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        
        class="form-item-w-50"
        status-icon
        :rules="rules"
      >
        <el-form-item label="增补单号：" prop="applyid">
          <el-input v-model="dynamicValidateForm.applyid" disabled />
          <el-button
            class="select-btn"
            type="primary"
            @click="
              (url, modal, params, row, prop, page) =>
                onSelectData(
                  _userapply_list,
                  'showUserapply',
                  { schoolorgid: dynamicValidateForm.schoolorgid },
                  itemRow,
                  'selectTable',
                  'selectPageInfo'
                )
            "
            >选择</el-button
          >
        </el-form-item>
        <el-form-item prop="companyorgname" label="分公司：">
          <el-input v-model="dynamicValidateForm.companyorgname" disabled />
        </el-form-item>
        <el-form-item prop="schoolorgname" label="教学点：">
          <el-input v-model="dynamicValidateForm.schoolorgname" disabled />
        </el-form-item>
        <el-form-item label="所属部门：" prop="departorgname">
          <el-input v-model="dynamicValidateForm.departorgname" disabled />
        </el-form-item>
        <el-form-item label="岗位：" prop="positionname">
          <el-input v-model="dynamicValidateForm.positionname" disabled />
        </el-form-item>

        <el-form-item label="姓名：" prop="username">
          <el-input v-model.trim="dynamicValidateForm.username" clearable/>
        </el-form-item>
        <el-form-item label="性别：" prop="sexkey">
          <el-select v-model="dynamicValidateForm.sexkey">
            <el-option label="男" value="1" />
            <el-option label="女" value="2" />
            <el-option label="不限" value="0" />
          </el-select>
        </el-form-item>
        <el-form-item label="电话：" prop="contactphone">
          <el-input v-model.trim="dynamicValidateForm.contactphone" maxlength="11" clearable/>
        </el-form-item>
        <el-form-item label="毕业院校：" prop="graduateschool">
          <el-input v-model.trim="dynamicValidateForm.graduateschool" clearable/>
        </el-form-item>
        <el-form-item label="专业：" prop="major">
          <el-input v-model.trim="dynamicValidateForm.major" clearable/>
        </el-form-item>
        <el-form-item label="上传简历：" prop="attachurl">
          <el-input v-model="dynamicValidateForm.attachurl" disabled />
          <el-upload
            class="upload-demo"
            :action="$store.state.global.baseUrl"
            multiple
            :before-upload="beforeUpload"
            :http-request="(prop) => uploadFile('attachurl')"
            :show-file-list="false"
          >
            <el-button type="primary" class="select-btn">点击上传</el-button>
            <div slot="tip" class="el-upload__tip"></div>
          </el-upload>
        </el-form-item>
        <el-form-item label="学历：" prop="edukey">
          <el-select v-model="dynamicValidateForm.edukey" clearable>
            <el-option
              v-for="item in eduList"
              :key="item.key"
              :value="item.key"
              :label="item.val"
            />
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="submitAddOrEditForm('dynamicValidateForm')"
          :loading="!$store.state.global.isEndRequest"
          >提交
        </el-button>
      </span>
    </el-dialog>

    <!-- 选择增补单 -->
    <el-dialog
      class="btn-2b5a95"
      width="80%"
			v-dialogDrag
      @close="onCloseSelect"
      title="选择增补单："
      :visible.sync="showUserapply"
			:close-on-click-modal='false'
			v-if="showUserapply"
      append-to-body
    >
      <el-button type="primary" @click="onConfirmUserapply" style="margin-bottom: 20px;">确定</el-button>

      <el-table
        :data="selectTable.list"
        style="width: 100%"
				stripe
				:key="key"	
				border
				:header-cell-style="{ background: '#044d8c', color: '#fff'}"
        ref="moneyTable"
        @selection-change="
          (val, multipleSelection) =>
            handleSelectionChange(val, 'multipleSelection')
        "
      >
        <el-table-column type="selection" width="55" />
        <el-table-column label="流水号" prop="id" align="center" />
        <el-table-column label="教学点" prop="schoolorgname" align="center" />
        <el-table-column label="增补岗位" prop="positionname" align="center" />
        <el-table-column
          label="申请时间"
          prop="applytime"
          align="center"
          width="110"
        >
          <template slot-scope="scope">
            {{ scope.row.applytime | formatDate("YYYY-MM-DD") }}
          </template>
        </el-table-column>
        <el-table-column
          label="希望到职时间"
          prop="wantedtime"
          align="center"
          width="110"
        >
          <template slot-scope="scope">
            {{ scope.row.wantedtime | formatDate("YYYY-MM-DD") }}
          </template>
        </el-table-column>

        <el-table-column label="编制人数" prop="allcount" align="center" />
        <el-table-column label="当前人数" prop="currentcount" align="center" />
        <el-table-column label="拟招人数" prop="applycount" align="center" />
        <el-table-column label="性别要求" prop="sexrequire" align="center" />
        <el-table-column label="审批标志" align="center">
          <template slot-scope="scope">
            {{ approveflag[scope.row.approveflag] }}
          </template>
        </el-table-column>
        <el-table-column label="增补理由" prop="applyreason" align="center" />
      </el-table>
      <el-pagination
        background
        @current-change="
          (val, data, prop, page) =>
            handleSelectChange(val, null, 'selectTable', 'selectPageInfo')
        "
        :current-page="selectPageInfo.pageindex"
        :page-size="selectPageInfo.pagesize"
        :page-sizes="[10, 20, 30, 40, 50]"
        layout="total, prev, pager, next, jumper"
        :total="selectTable.totalcount"
      ></el-pagination>
    </el-dialog>

    <!-- 查看预约信息 - 列表 -->
    <el-dialog
      width="80%"
			v-dialogDrag
      title="查看预约信息："
      :visible.sync="showUserinterview"
			:close-on-click-modal='false'
			v-if="showUserinterview"
      append-to-body
    >
      <div class="eaf4ff-btn" style="margin-bottom: 20px;">
        <el-button type="primary" @click="onUserinterviewAdd">增加</el-button>
        <el-button type="primary" @click="onUserinterviewEdit">修改</el-button>
        <el-button type="primary" @click="onUserinterviewDel">删除</el-button>
        <el-button type="primary" @click="onUserinterviewFlag"
          >设置初试结果</el-button
        >
      </div>

      <el-table
        :data="userinterview_select.list"
				stripe
				border
				:key="key"	
				:header-cell-style="{ background: '#044d8c', color: '#fff'}"
        style="width: 100%"
        ref="moneyTable"
        @selection-change="
          (val, multipleSelection) =>
            handleSelectionChange(val, 'lowest_multipleSelection')
        "
      >
        <el-table-column type="selection" width="55" align="center" />
        <el-table-column
          label="流水号"
          prop="id"
          align="center"
          width="100"
        ></el-table-column>
        <el-table-column
          label="用户名称"
          prop="username"
          align="center"
        ></el-table-column>
        <el-table-column
          label="联系电话"
          prop="contactphone"
          align="center"
          width="120"
        ></el-table-column>
        <el-table-column
          label="面试类别"
          prop="interviewtypeval"
          align="center"
        ></el-table-column>
        <el-table-column
          label="面试官姓名"
          prop="interviewusername"
          align="center"
          width="100"
        ></el-table-column>
        <el-table-column
          label="面试时间"
          prop="interviewtime"
          align="center"
          width="160"
        >
          <template slot-scope="scope">
            {{ scope.row.interviewtime | formatDate("YYYY-MM-DD HH:mm:ss") }}
          </template>
        </el-table-column>
        <el-table-column
          label="面试地址"
          prop="interviewaddr"
          align="center"
        ></el-table-column>
        <el-table-column label="审核标识" prop="flag" align="center">
          <template slot-scope="scope">
            {{ isApprovePass[scope.row.flag] }}
          </template>
        </el-table-column>
        <el-table-column
          label="备注"
          prop="remark"
          align="center"
        ></el-table-column>
        <el-table-column
          label="教学点"
          prop="schoolorgname"
          align="center"
        ></el-table-column>
      </el-table>
      <el-pagination
        background
        @current-change="
          (val, data, prop, page) =>
            handleSelectChange(
              val,
              { personid: itemRow.id },
              'userinterview_select',
              'userinterview_page'
            )
        "
        :current-page="userinterview_page.pageindex"
        :page-size="userinterview_page.pagesize"
        :page-sizes="[10, 20, 30, 40, 50]"
        layout="total, prev, pager, next, jumper"
        :total="userinterview_select.totalcount"
      ></el-pagination>
    </el-dialog>
    <!-- 查看预约信息 - 增加，修改 -->
    <el-dialog
      class="btn-2b5a95"
      width="600px"
			v-dialogDrag
      :title="dialogTitle"
      :visible.sync="showUserinterAdd"
			:close-on-click-modal='false'
			v-if="showUserinterAdd"
      append-to-body
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        
        class="demo-dynamic form-item-w-100"
        status-icon
        :rules="rules"
      >
        <el-form-item label="人才：" prop="username">
          <el-input v-model="dynamicValidateForm.username" disabled />
        </el-form-item>
        <el-form-item label="联系电话：" prop="contactphone">
          <el-input v-model="dynamicValidateForm.contactphone" disabled />
        </el-form-item>
        <el-form-item label="面试时间：" prop="interviewtime">
          <el-date-picker
            v-model="dynamicValidateForm.interviewtime"
            type="datetime"
            placeholder="选择日期时间"
            value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="面试官：" prop="interviewusername">
          <el-input v-model="dynamicValidateForm.interviewusername" disabled />
          <el-button
            class="select-btn"
            type="primary"
            @click="
              (url, modal, params, row, prop, page) =>
                onSelectData(
                  user_dialogresult,
                  'showUserModal',
                  {
                    schoolorgid: dynamicValidateForm.schoolorgid,
                  },
                  itemRow,
                  'selectTable',
                  'selectPageInfo'
                )
            "
            >选择</el-button
          >
        </el-form-item>
        <el-form-item label="面试类别：" prop="interviewtypekey">
          <el-select
            v-model="dynamicValidateForm.interviewtypekey"
            placeholder="请选择"
            clearable
          >
            <el-option
              v-for="item in interviewCate"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="submitUserinterSave('dynamicValidateForm')"
          :loading="!$store.state.global.isEndRequest"
          >提交
        </el-button>
      </span>
    </el-dialog>
    <!-- 查看预约信息 - 设置 -->
    <el-dialog
      class="btn-2b5a95"
      width="600px"
			v-dialogDrag
      title="审核："
      :visible.sync="showUserinterFlag"
			:close-on-click-modal='false'
			v-if="showUserinterFlag"
      append-to-body
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        
        class="demo-dynamic form-item-w-100"
        status-icon
        :rules="rules"
      >
        <el-form-item label="是否通过：" prop="flag">
          <el-select
            v-model="dynamicValidateForm.flag"
            placeholder="请选择"
            clearable
          >
            <el-option
              v-for="item in isApprovePasss"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注：" prop="remark">
          <el-input type="textarea" v-model.trim="dynamicValidateForm.remark" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="submitUserinterFlag('dynamicValidateForm')"
          :loading="!$store.state.global.isEndRequest"
          >提交
        </el-button>
      </span>
    </el-dialog>

    <!-- 预约面试 -->
    <el-dialog
      class="btn-2b5a95"
      title="预约面试："
			v-dialogDrag
      :visible.sync="showInterviewModal"
			:close-on-click-modal='false'
			v-if="showInterviewModal"
      append-to-body
      width="35%"
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        class="demo-dynamic form-item-w-100"
        status-icon
        
        :rules="rules"
      >
        <el-form-item label="人才：" prop="username">
          <el-input v-model="dynamicValidateForm.username" disabled />
        </el-form-item>
        <el-form-item label="联系电话：" prop="contactphone">
          <el-input v-model="dynamicValidateForm.contactphone" disabled />
        </el-form-item>
        <el-form-item label="面试官：" prop="interviewusername">
          <el-input v-model="dynamicValidateForm.interviewusername" disabled />
          <el-button
            class="select-btn"
            type="primary"
            @click="
              (url, modal, params, row, prop, page) =>
                onSelectData(
                  user_dialogresult,
                  'showUserModal',
                  {
                    schoolorgid: dynamicValidateForm.schoolorgid,
                  },
                  itemRow,
                  'selectTable',
                  'selectPageInfo'
                )
            "
            >选择</el-button
          >
        </el-form-item>
        <el-form-item label="面试时间：" prop="interviewtime">
          <el-date-picker
            type="datetime"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            v-model="dynamicValidateForm.interviewtime"
            placeholder="选择日期时间"
          />
        </el-form-item>
        <el-form-item label="面试类别：" prop="interviewtypekey">
          <el-select v-model="dynamicValidateForm.interviewtypekey">
            <el-option value="01" label="初试"></el-option>
            <el-option value="02" label="复试"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注：" prop="remark">
          <el-input v-model.trim="dynamicValidateForm.remark" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="submitAppointmentForm('dynamicValidateForm')"
          >提交
        </el-button>
      </span>
    </el-dialog>

    <!-- 选择面试官 -->
    <el-dialog
      class="btn-2b5a95"
      width="50%"
			v-dialogDrag
      @close="onCloseSelect"
      title="选择面试官："
      :visible.sync="showUserModal"
			:close-on-click-modal='false'
			v-if="showUserModal"
      append-to-body
    >
      <el-button type="primary" @click="onConfirmUser">确定</el-button>

      <div class="index_2 btn-2b5a95">
        <div
          class="l-w"
          :style="{
            height: $store.state.global.isSearchExtend_2
              ? ''
              : $store.state.global.searchFormHeight_2 + 10 + 'px',
          }"
        >
          <el-form
            label-position="right"
            :class="
              $store.state.global.isSearchExtend_2 ? '' : 'overflow--hidden'
            "
            :model="selectSearchForm"
						@submit.native.prevent
          >
            <el-form-item label="真实姓名:" prop="realname">
              <el-input 
								v-model.trim="selectSearchForm.realname" 
								@keyup.enter.native="(url, modal, params, row, prop, page) =>
                onSelectSearchClick(
                  selectTableUrl,
                  null,
                  Object.assign(selectSearchForm, {
                    schoolorgid: dynamicValidateForm.schoolorgid,
                  }),
                  itemRow,
                  'selectTable',
                  'selectPageInfo'
                )"
								clearable />
            </el-form-item>
          </el-form>
        </div>
        <div class="r-w">
          <el-button
            type="primary"
            icon="el-icon-search"
            :loading="!$store.state.global.isEndRequest"
            class="btn-2b5a95"
            @click="
              (url, modal, params, row, prop, page) =>
                onSelectSearchClick(
                  selectTableUrl,
                  null,
                  Object.assign(selectSearchForm, {
                    schoolorgid: dynamicValidateForm.schoolorgid,
                  }),
                  itemRow,
                  'selectTable',
                  'selectPageInfo'
                )
            "
            >搜索</el-button
          >
					<el-button type="primary" @click="handleQuery">重置</el-button>
          <el-button
            type="primary"
            :icon="
              $store.state.global.isSearchExtend_2
                ? 'el-icon-arrow-up'
                : 'el-icon-arrow-down'
            "
            v-if="$store.state.global.searchExtend_2"
            class="btn-2b5a95"
            @click="onExtendClick('isSearchExtend_2')"
            >{{ $store.state.global.searchExtendText_2 }}</el-button
          >
        </div>
      </div>

      <el-table
        :data="selectTable.list"
        style="width: 100%"
        ref="moneyTable"
				stripe
				:key="key"	
				border
				:header-cell-style="{ background: '#044d8c', color: '#fff'}"
        @selection-change="
          (val, multipleSelection) =>
            handleSelectionChange(val, 'multipleSelection')
        "
      >
        <el-table-column type="selection" width="55" align="center" />
        <el-table-column
          label="流水号"
          prop="id"
          align="center"
        ></el-table-column>
        <el-table-column
          label="姓名"
          prop="realname"
          align="center"
        ></el-table-column>
        <el-table-column
          label="性别"
          prop="sexval"
          align="center"
        ></el-table-column>
        <el-table-column
          label="教学点"
          prop="schoolorgname"
          align="center"
        ></el-table-column>
      </el-table>
      <el-pagination
        background
        @current-change="
          (val, data, prop, page) =>
            handleSelectChange(
              val,
              { schoolorgid: dynamicValidateForm.schoolorgid },
              'selectTable',
              'selectPageInfo'
            )
        "
        :current-page="selectPageInfo.pageindex"
        :page-size="selectPageInfo.pagesize"
        :page-sizes="[10, 20, 30, 40, 50]"
        layout="total, prev, pager, next, jumper"
        :total="
          selectTable.paginationutil ? selectTable.paginationutil.totalcount : 0
        "
      ></el-pagination>
    </el-dialog>
  </div>
</template>

<script>
import { part } from "@/utils/mixins";
import { mapActions } from "vuex";
import {
  userapply_input,
  userinterview_list,
  person_list,
  person_input,
  person_save,
  person_delete,
  userinterview_save,
  userinterview_delete,
  userinterview_flag,
  userapply_list,
} from "@/request/api/allChildrenProject";
import {
  _fileupload_save,
  _filedownload_save,
  _allCompany,
  _allSchool,
  _orgposition_combox,
} from "@/request/api/public";
import {
  _approveflag,
  _isApprovePass,
  _isApprovePasss,
} from "@/assets/js/filedValueFlag";
import { _user_dialogresult } from "@/request/api/public";
import { myRequest } from "@/request/index";
import {
  selectCheck,
  confirmCallBack,
  messageTip,
  createObjParams,
  formatDare,
  PanelControl,
} from "@/utils/util";

export default {
  mixins: [part],
  name: "building",
  components: {},
  props: {
    user_dialogresult: {
      default: () => _user_dialogresult,
    },

    isApprovePass: {
      default: () => _isApprovePass,
    },
    isApprovePasss: {
      default: () => _isApprovePasss,
    },

    approveflag: {
      default: () => _approveflag,
    },
    _userapply_list: {
      default: () => userapply_list,
    },
    _userinterview_list: {
      default: () => userinterview_list,
    },
  },
  data() {
    return {
      itemRow: null, //表格项
      searchForm: {}, //index - 搜索
      tableRes: {}, //列表接口
      pageinfo: {
        pageindex: 1,
        pagesize: 10,
      }, //页数查询
      showInterviewModal: false, // 预约面试
      showUserModal: false, // 面试官
      personPageinfo: {
        pageindex: 1,
        pagesize: 10,
      }, //页数查询

      selectTable: {},
      selectTableUrl: null,
      
      selectSchoolorgid: null,
      selectSearchForm: {},
      selectPageInfo: {
        pageindex: 1,
        pagesize: 10,
      }, // 选项

      showAddModal: false, // 添加
      eduList: [], // 学历
      showDetailModal: false, // 查看详情
      multipleSelection: [], //选中的表格项
      dynamicValidateForm: {}, //表单
      rules: {
        companyorgname: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        schoolorgname: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        departorgname: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        positionname: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        edukey: [{ trigger: "blur", message: "必填字段", required: true }],
        sexrequire: [{ trigger: "blur", message: "必填字段", required: true }],
        allcount: [{ trigger: "blur", message: "必填字段", required: true }],
        currentcount: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        applycount: [{ trigger: "blur", message: "必填字段", required: true }],
        applyreason: [{ trigger: "blur", message: "必填字段", required: true }],
        wantedtime: [{ trigger: "blur", message: "必填字段", required: true }],
        username: [{ trigger: "blur", message: "必填字段", required: true }],
        sexkey: [{ trigger: "blur", message: "必填字段", required: true }],
        contactphone: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        attachurl: [{ trigger: "blur", message: "必填字段", required: true }],
        interviewtime: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        interviewusername: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        interviewtypekey: [
          { trigger: "blur", message: "必填字段", required: true },
        ],
        flag: [{ trigger: "blur", message: "必填字段", required: true }],
        remark: [{ trigger: "blur", message: "必填字段", required: false }],
        applyid: [{ trigger: "blur", message: "必填字段", required: true }],
      }, //表单规则
      dialogTitle: "", //对话框标题
      fds: new FormData(),

      //查看预约信息
      userinterview_select: {}, //数据
      userinterview_page: {
        //分页
        pageindex: 1,
        pagesize: 10,
      },
      showUserinterview: false, //- 列表
      showUserinterAdd: false, // - 增加，修改
      showUserinterDel: false, // - 删除
      showUserinterFlag: false, // - 设置

      interviewCate: [
        {
          value: "01",
          label: "初试",
        },
        {
          value: "02",
          label: "复试",
        },
      ], //面试类别
      lowest_multipleSelection: [], //选中的表格项
			key: '',
      showUserapply: false, //选择增补单
    };
  },
  created() {
    // this.initPanelControl(['index','detail'])

    this.getDataList();
    this.org_combox();
    this.org_comboxchild(this.dynamicValidateForm.companyorgid);
    this.dic_combox({
      list: "eduList",
      typecode: "edu",
      that: this,
    });
		window.addEventListener("keyup", this.enterSelect);
  },
  methods: {
    ...mapActions("global", ["onExtendClick", "isExpand"]),
    ...mapActions("selectData", [
      "dic_combox",
      "org_combox",
      "org_comboxchild",
    ]),

    /*
    //初始化表格面板控制器 event
    initPanelControl(panelKeys) {
      for (let i in panelKeys) {
        new PanelControl({
          self: this, //绑定的位置
          dataKey: panelKeys[i], //数据唯一标识
        });
      }
    },
*/
    //改变分公司 change
    onChangeCompany(val, form) {
      this[form].schoolorgname = "";
      this[form].schoolorgid = "";
      this[form].companyorgid = val;
      this.org_comboxchild(val);
    },
    //改变教学点 change
    onChangeSchool(val, form) {
      this[form].schoolorgid = val;
    },
		enterSelect(e) {
			if(e.keyCode == 13 ) {
				this.getDataList();
			}
		},
		visibleType(e) {
			if(!e) {
				this.$refs.selectref.blur();
			}
		},
		reset() {
			this.searchForm = {
				username: null,
				sexval: null,
				contactphone: null,
				positionname: null,
				eduval: null,
				graduateschool: null,
				major: null,
				schoolorgname: null,
				
			}
		},
		resetQuery() {
			this.reset();
			this.getDataList();
		},
		selectQuery() {
			this.selectSearchForm = {
				realname: null
			}
		},
		handleQuery() {
			this.selectQuery();
			this.onSelectData(this.selectTableUrl, null, {
                    schoolorgid: this.dynamicValidateForm.schoolorgid,
                  }, this.itemRow,
                  'selectTable',
                  'selectPageInfo');
		},
    //搜索 click
    seach() {
      this.pageinfo.pageindex = 1;
      this.getDataList();
    },
    //列表查询 api
    getDataList() {
      myRequest({
        url: person_list,
        data: Object.assign(this.searchForm, this.pageinfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.tableRes = res.data.data;
        }
      });
    },
    //切换当前页
    handleCurrentChange(val) {
      this.pageinfo.pageindex = val;
      this.getDataList();
    },
    //触发所有btn方法
    onClickBtn(methodsName) {
      this.$options.methods[methodsName].call(this); //btnAdd ... （防止修改this指向）
    },
    // 查看详情 click
    btnShowDetail(row) {
      this.dynamicValidateForm = JSON.parse(JSON.stringify(row));
      this.showDetailModal = true;
    },
    //刷新 click
    btnReload() {
      this.getDataList();
    },
    //选中表格数据改变时
    handleSelectionChange(val, prop) {
      this[prop] = val;
    },
    //添加click
    btnAdd() {
      this.dialogTitle = "添加";
      this.dynamicValidateForm = {
        schoolorgid: this.$store.state.login.userinfo.schoolorgid,
        companyorgid: this.$store.state.login.userinfo.companyorgid,
      };
      this.$set(
        this.dynamicValidateForm,
        "companyorgname",
        this.$store.state.login.userinfo.companyorgname
      );
      this.$set(
        this.dynamicValidateForm,
        "schoolorgname",
        this.$store.state.login.userinfo.schoolorgname
      );
      this.org_comboxchild(this.dynamicValidateForm.companyorgid);
      this.showAddModal = true;
    },
    //修改click
    btnEdit() {
      this.dialogTitle = "修改";
      selectCheck(this.multipleSelection, "修改", false, () => {
        this.editRoutePage(this.multipleSelection[0].id);
      });
    },
    //修改路由页 api
    editRoutePage(id) {
      myRequest({
        url: person_input,
        data: {
          id: id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.dynamicValidateForm = res.data.data.vperson;
          this.org_comboxchild(this.dynamicValidateForm.companyorgid);
          delete this.dynamicValidateForm.inserttime; //inserttime 该字段不用传
          delete this.dynamicValidateForm.deleteflag; //deleteflag 该字段不用传
          this.showAddModal = true;
        }
      });
    },
    //提交-（添加，修改） api
    submitAddOrEditForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          myRequest(
            {
              url: person_save,
              data: this.dynamicValidateForm,
            },
            {
              isRefTableList: true,
              that: this,
              modal: "showAddModal",
            }
          );
        }
      });
    },
    //删除click
    btnDel() {
      selectCheck(this.multipleSelection, "删除", true, () => {
        confirmCallBack({
          title: "提示",
          content: "此操作将永久删除选中的数据！",
          success: () => {
            this.delTableItem(this.multipleSelection.map((i) => i.id));
          },
          fail: () => {
            messageTip({
              message: "取消操作",
              type: "info",
            });
          },
        });
      });
    },
    //删除 api
    delTableItem(idArr) {
      myRequest(
        {
          method: "post",
          url: person_delete,
          data: this.$qs.stringify(
            {
              ids: idArr,
            },
            {
              arrayFormat: "repeat",
            }
          ),
        },
        {
          isRefTableList: true,
          that: this,
        }
      );
    },

    //上传文件前 before
    beforeUpload(file) {
      this.fds.append("upfile", file); // 传文件
    },
    //上传文件 upload
    uploadFile(prop) {
      myRequest({
        method: "post",
        url: _fileupload_save,
        data: this.fds,
      }).then((res) => {
        if (res.data.code === "200") {
          this.fds = new FormData();
          this.$set(this.dynamicValidateForm, prop, res.data.url);
        }
      });
    },

    //下载人才简历 click
    btnJianli(row) {
      location.href =
        this.$url.Freemarker + _filedownload_save + "?fileurl=" + row.attachurl;
    },
    // 预约面试 click
    btnInterview(row) {
      this.dynamicValidateForm = {
        personid: row.id,
        username: row.username,
        contactphone: row.contactphone,
        schoolorgid: row.schoolorgid,
      };
      this.showInterviewModal = true;
    },
    // 预约面试 api
    submitAppointmentForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          myRequest(
            {
              url: userinterview_save,
              data: this.dynamicValidateForm,
            },
            {
              that: this,
              isRefTableList: true,
              modal: "showInterviewModal",
            }
          );
        }
      });
    },

    //查看预约信息-增加 click
    onUserinterviewAdd() {
      this.dialogTitle = "增加：";
      this.dynamicValidateForm = {
        username: this.itemRow.username,
        personid: this.itemRow.id,
        schoolorgid: this.itemRow.schoolorgid,
        companyorgid: this.itemRow.companyorgid,
        contactphone: this.itemRow.contactphone,
      };
      this.showUserinterAdd = true;
    },
    //查看预约信息-修改 click
    onUserinterviewEdit() {
      this.dialogTitle = "修改：";
      selectCheck(this.lowest_multipleSelection, "修改", false, () => {
        this.dynamicValidateForm = JSON.parse(
          JSON.stringify(this.lowest_multipleSelection[0])
        );
        this.$set(
          this.dynamicValidateForm,
          "interviewtime",
          formatDare(
            this.dynamicValidateForm.interviewtime,
            "YYYY-MM-DD HH:mm:ss"
          )
        );
        this.showUserinterAdd = true;
      });
    },
    //查看预约信息-删除 click
    onUserinterviewDel() {
      selectCheck(this.lowest_multipleSelection, "删除", true, () => {
        confirmCallBack({
          title: "提示",
          content: "此操作将永久删除选中的数据！",
          success: () => {
            this.delUserinterview(
              this.lowest_multipleSelection.map((i) => i.id)
            );
          },
          fail: () => {
            messageTip({
              message: "取消操作",
              type: "info",
            });
          },
        });
      });
    },
    //查看预约信息-设置 click
    onUserinterviewFlag() {
      selectCheck(this.lowest_multipleSelection, "操作", false, () => {
        this.dynamicValidateForm = {
          id: this.lowest_multipleSelection[0].id,
        };
        this.showUserinterFlag = true;
      });
    },
    //查看预约信息-增加，修改提交 click api
    submitUserinterSave(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          myRequest({
            url: userinterview_save,
            data: this.dynamicValidateForm,
          }).then((res) => {
            if (res.data.code === "200") {
              this.onSelectData(
                this._userinterview_list,
                null,
                { personid: this.itemRow.id },
                this.itemRow,
                "userinterview_select",
                "userinterview_page"
              );
              this.showUserinterAdd = false;
            }
          });
        }
      });
    },
    //查看预约信息-删除 api
    delUserinterview(idArr) {
      myRequest({
        method: "post",
        url: userinterview_delete,
        data: this.$qs.stringify(
          {
            ids: idArr,
          },
          {
            arrayFormat: "repeat",
          }
        ),
      }).then((res) => {
        if (res.data.code === "200") {
          this.onSelectData(
            this._userinterview_list,
            null,
            { personid: this.itemRow.id },
            this.itemRow,
            "userinterview_select",
            "userinterview_page"
          );
        }
      });
    },
    //查看预约信息-设置提交 click api
    submitUserinterFlag(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          myRequest({
            url: userinterview_flag,
            data: this.dynamicValidateForm,
          }).then((res) => {
            if (res.data.code === "200") {
              this.onSelectData(
                this._userinterview_list,
                null,
                { personid: this.itemRow.id },
                this.itemRow,
                "userinterview_select",
                "userinterview_page"
              );
              this.showUserinterFlag = false;
            }
          });
        }
      });
    },
    //切换选项表格页
    handleSelectChange(val, data, prop, page) {
      this.selectPageInfo.pageindex = val;
      this.onSelectData(
        this.selectTableUrl,
        null,
        data,
        this.itemRow,
        prop,
        page
      );
    },
    //选项表格 api
    onSelectData(url, modal, data, row, prop, page) {
      this.itemRow = row;
      this.selectTableUrl = url;
      myRequest({
        url: this.selectTableUrl,
        data: Object.assign(data || {}, this[page]),
      }).then((res) => {
        if (res.data.code === "200") {
          this.$refs.multipleTable && this.$refs.multipleTable.clearSelection();
          this[prop] = res.data.data;
					this.key = Math.random();
          this[modal] = true;
        }
      });
    },
    //选项表格 - 搜索 click
    onSelectSearchClick(url,modal,data,row,prop,page){
      this.selectPageInfo.pageindex = 1
      this.onSelectData(url,modal,data,row,prop,page)
    },
    //关闭选项框 click
    onCloseSelect(){
      this.selectPageInfo.pageindex = 1
    },
    //确认面试官 click
    onConfirmUser() {
      selectCheck(this.multipleSelection, "确认面试官", false, () => {
        this.$set(
          this.dynamicValidateForm,
          "interviewusername",
          this.multipleSelection[0].realname
        );
        this.dynamicValidateForm.interviewuserid = this.multipleSelection[0].id;
        this.showUserModal = false;
				this.selectQuery();
				this.multipleSelection = [];
      });
    },
    //确认增补单 click
    onConfirmUserapply() {
      selectCheck(this.multipleSelection, "确认增补单", false, () => {
        const vals = {
          applyid: "id",
          schoolorgid: "schoolorgid",
          companyorgid: "companyorgid",
          companyorgname: "companyorgname",
          schoolorgname: "schoolorgname",
          departorgname: "departorgname",
          departorgid: "departorgid",
          positionname: "positionname",
          positionid: "positionid",
        };
        for (let key in vals) {
          this.$set(
            this.dynamicValidateForm,
            key,
            this.multipleSelection[0][vals[key]]
          );
        }
        this.showUserapply = false;
      });
    },
  },
};
</script>

<style  lang="scss">
</style>